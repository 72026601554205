<template lang="pug">
    .letter-grade.box(:class="average")
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'LetterGrade',
  computed: {
    ...get({ submission: 'submissions/submission' }),
    average() {
      const {
        grammar,
        isClear,
        vocabulary,
        appropriateIdioms,
        appropriateTone
      } = this.submission.ratings

      const avg = parseFloat(
        (grammar + isClear + appropriateIdioms + appropriateTone + vocabulary) / 5
      ).toFixed(2)

      if (avg >= 2.8) return 'a-plus'
      else if (avg >= 2.6) return 'a'
      else if (avg >= 2.2) return 'b-plus'
      else if (avg >= 2) return 'b'
      else if (avg >= 1.8) return 'c'
      else if (avg >= 1.25) return 'd'
      else return 'f'
    }
  }
}
</script>

<style scoped lang="scss">
div.box {
  width: 100%;
  /* width:100vw; */
  /* max-width:600px; */
  /* border:solid 1px #333; */
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 10px;
  color: #999;
  position: relative;
}

div.box:after {
  position: absolute;
  /* top:70px; */

  right: 0px;
  z-index: 1;
  font-family: Arial, sans-serif;
  -webkit-transform: rotate(-45deg); /* Safari */
  -moz-transform: rotate(-45deg); /* Firefox */
  -ms-transform: rotate(-45deg); /* IE */
  -o-transform: rotate(-45deg); /* Opera */
  transform: rotate(15deg);
  font-size: 140px;
  color: #c00;
  background: #fff;
  border: solid 7px #c00;
  /* border-radius:50%; */
  padding-left: 15px;
  padding-right: 15px;
  zoom: 1;
  filter: alpha(opacity=7);
  opacity: 0.07;
  -webkit-text-shadow: 0 0 2px #c00;
  text-shadow: 0 0 2px #c00;
  box-shadow: 0 0 2px #c00;
}

.a:after {
  content: "A";
}
.a-plus:after {
  content: "A+";
}
.b-plus:after {
  content: "B+";
}
.b:after {
  content: "B";
}
.c:after {
  content: "C";
}
.d:after {
  content: "D";
}
.f:after {
  content: "F";
}
</style>
